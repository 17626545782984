











































































.checkbox {
  @import '~@/styles/import';

  &-checked, &-unchecked {
    &.invalid {
      @apply border-error;
    }
  }

  &-label {
    &:focus-within {
      .checkbox {
        &-checked, &-unchecked {
          @apply border-primary;
        }
      }
    }

    a {
      @apply text-primary underline font-bold cursor-pointer;
    }
  }
}
